import "core-js/modules/es.object.values.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "insurers"
  }, [_c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.openAddPopup
    }
  }, [_vm._v("Neu")]), _c('insurers', {
    attrs: {
      "insurers": Object.values(_vm.insurers.data)
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };