import { render, staticRenderFns } from "./Insurers.vue?vue&type=template&id=5fc2ca5f&scoped=true&lang=pug&"
import script from "./Insurers.vue?vue&type=script&lang=ts&"
export * from "./Insurers.vue?vue&type=script&lang=ts&"
import style0 from "./Insurers.vue?vue&type=style&index=0&id=5fc2ca5f&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc2ca5f",
  null
  
)

export default component.exports