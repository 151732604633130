import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "entries"
  }, [_vm._l(_vm.value, function (entry, idx) {
    return [_c('button', {
      key: "add-".concat(idx),
      staticClass: "btn _clear",
      attrs: {
        "data-testid": "add-".concat(idx)
      },
      on: {
        "click": function click($event) {
          return _vm.addItemBefore(idx);
        }
      }
    }, [_c('i', {
      staticClass: "icon-add-above"
    })]), _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: entry.type,
        expression: "entry.type"
      }],
      key: "type-".concat(idx),
      staticClass: "input",
      attrs: {
        "id": "input-type-".concat(idx)
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.$set(entry, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, _vm._l(_vm.typeOptions, function (opt) {
      return _c('option', {
        domProps: {
          "value": opt
        }
      }, [_vm._v(_vm._s(opt))]);
    }), 0), _vm.type === 'staggered' ? [idx < _vm.value.length - 1 ? _c('money', {
      key: "up-to-".concat(idx),
      staticClass: "input",
      attrs: {
        "id": "input-up-to-".concat(idx)
      },
      model: {
        value: entry.upTo,
        callback: function callback($$v) {
          _vm.$set(entry, "upTo", $$v);
        },
        expression: "entry.upTo"
      }
    }) : _c('div', {
      key: "up-to-".concat(idx),
      staticStyle: {
        "line-height": "2rem"
      },
      attrs: {
        "data-testid": "up-to-".concat(idx)
      }
    }, [_vm._v("ab " + _vm._s(_vm.upperBoundary))])] : _c('div'), _c('money', {
      key: "amount-".concat(idx),
      staticClass: "input",
      attrs: {
        "id": "input-amount-".concat(idx)
      },
      model: {
        value: entry.amount,
        callback: function callback($$v) {
          _vm.$set(entry, "amount", $$v);
        },
        expression: "entry.amount"
      }
    }), _vm.type === 'manual' || idx < _vm.value.length - 1 ? _c('button', {
      staticClass: "btn _clear",
      attrs: {
        "data-testid": "delete-".concat(idx)
      },
      on: {
        "click": function click($event) {
          return _vm.removeItem(idx);
        }
      }
    }, [_c('i', {
      staticClass: "icon-trashbin"
    })]) : _c('div', {
      key: "delete-".concat(idx)
    })];
  })], 2), !_vm.value.length || _vm.type === 'manual' ? _c('button', {
    staticClass: "btn _clear",
    attrs: {
      "data-testid": "btn-add"
    },
    on: {
      "click": _vm.appendItem
    }
  }, [_c('i', {
    staticClass: "icon-add"
  })]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };