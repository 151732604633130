var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal-container', {
    attrs: {
      "title": "Neuen Versicherer anlegen"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('insurer', {
    attrs: {
      "insurer": _vm.insurer
    }
  }), _c('button', {
    staticClass: "btn",
    attrs: {
      "data-testid": "save"
    },
    on: {
      "click": _vm.onSaveClicked
    }
  }, [_vm._v("Speichern")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };