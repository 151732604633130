var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card"
  }, [_vm.$slots.title ? _c('div', {
    staticClass: "card-header"
  }, [_vm._t("title")], 2) : _vm._e(), _c('div', {
    staticClass: "card-content"
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };