import { render, staticRenderFns } from "./InsurersPage.vue?vue&type=template&id=272272dc&scoped=true&lang=pug&"
import script from "./InsurersPage.vue?vue&type=script&lang=ts&"
export * from "./InsurersPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272272dc",
  null
  
)

export default component.exports