import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h4', [_vm._v("Versicherer")])];
      },
      proxy: true
    }])
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Referenz")])])]), _c('tbody', _vm._l(_vm.insurers, function (ins) {
    return _c('tr', {
      key: ins.reference,
      staticClass: "hoverTr",
      on: {
        "click": function click($event) {
          return _vm.openEditPopup(ins);
        }
      }
    }, [_c('td', [_vm._v(_vm._s(ins.name))]), _c('td', [_vm._v(_vm._s(ins.reference))])]);
  }), 0)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };