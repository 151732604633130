import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('article', [_c('card', [_c('input-field', {
    attrs: {
      "label": "Name:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.insurer.name,
      expression: "insurer.name"
    }],
    staticClass: "input",
    attrs: {
      "id": "insurer-name"
    },
    domProps: {
      "value": _vm.insurer.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.insurer, "name", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "Referenz:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.insurer.reference,
      expression: "insurer.reference"
    }],
    staticClass: "input",
    attrs: {
      "id": "insurer-reference"
    },
    domProps: {
      "value": _vm.insurer.reference
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.insurer, "reference", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "Typ:"
    }
  }, [_c('select', {
    staticClass: "input",
    domProps: {
      "value": _vm.insurer.paymentType || 'staggered'
    },
    on: {
      "input": function input($event) {
        return _vm.$set(_vm.insurer, 'paymentType', $event.target.value);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "staggered"
    }
  }, [_vm._v("Gestaffelt")]), _c('option', {
    attrs: {
      "value": "manual"
    }
  }, [_vm._v("Manuell")])])]), _c('input-field', {
    attrs: {
      "label": "Konfiguration:"
    }
  }, [_c('payment-configuration', {
    attrs: {
      "value": _vm.insurer.paymentConfiguration,
      "type": _vm.insurer.paymentType
    },
    on: {
      "update:type": function updateType($event) {
        return _vm.$set(_vm.insurer, "paymentType", $event);
      }
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };